import React, { useEffect, useState } from "react";
import "./announcements.css";
import SearchForm from "../admin/search-form/SearchForm";
import AnnouncementsFilter from "../announcements-filter/AnnouncementsFilter";
import ElementCard from "../element-card/ElementCard";
import axios from "axios";
import { useSelector } from "react-redux";

const Announcements = ({ userData }) => {
  const [ann, setAnn] = useState([]);
  const [brokers, setBrokers] = useState([]);
  const [annImages, setAnnImages] = useState([]);
  const [showAnn, setShowAnn] = useState([])

  useEffect(() => {
    const get_items = async () => {
      try {
        const { data } = await axios.get(
          "https://service.homely.am/api/items/all"
        );
        setShowAnn(data.all_items.reverse());
        setAnn(data.all_items);
        setAnnImages(() => data.all_images);
      } catch (err) {
        console.log(err);
      }
    };
    get_items();
    const get_brokers = async () => {
      try {
        const { data } = await axios.get(
          "https://service.homely.am/api/admin/get_all"
        );
        setBrokers(() => data.data);
      } catch (error) {
        console.log(error);
      }
    };

    get_brokers();
  }, []);

  function filtracion(fi) {
		setShowAnn(
			ann.filter(el => {
				if (fi.id && Number(fi.id) !== el.id) return false
				if (fi.ownerNumber && fi.ownerNumber !== el.owner_phone) return false
				if (
					fi.address.length &&
					(el.street == null ||
						!el.street.toLowerCase().startsWith(fi.address.toLowerCase()))
				)
					return false
				if (fi.regions.length && !fi.regions.includes(el.region)) return false
				if (fi.rentOrSell && el.type !== fi.rentOrSell) return false
				if (
					fi.roomsNumber.length &&
					!fi.roomsNumber.includes(el.number_of_rooms)
				)
					return false
				if (
					fi.status != undefined &&
					fi.status != [] &&
					!fi.status.includes(el.status)
				)
					return false
				if (
					fi.building_type != undefined &&
					fi.building_type != [] &&
					!fi.building_type.includes(el.type_of_building)
				)
					return false
				if (fi.floor_min && Number(el.floor) < Number(fi.floor_min))
					return false
				if (fi.floor_max && Number(el.floor) > Number(fi.floor_max))
					return false
				if (fi.price_from && Number(el.price) < Number(fi.price_from))
					return false
				if (fi.price_to && Number(el.price) > Number(fi.price_to)) return false
				if (fi.itemType.length && !fi.itemType.includes(el.item_type))
					return false
				return true
			})
		)
	}

  return (
		<div className='workers-page'>
			<div style={{ height: '100px' }}></div>
			<AnnouncementsFilter onFiltracion={fi => filtracion(fi)} />
			<div className='all_ann'>
				{showAnn.map(el => {
					const itemIMAGES = annImages.filter(e => e.item_id == el.id)
					const brokerData = brokers.filter(e => e.id === Number(el.adminid))
					return (
						<div className='el_data_card'>
							<ElementCard
								price={el.price}
								id={el.id}
								img={
									itemIMAGES[0] &&
									`https://service.homely.am/storage/images/${itemIMAGES[0].image}`
								}
								title={el.title}
								status={null}
								area={el.area}
								bathrooms={el.number_of_bathrooms}
								rooms={el.number_of_rooms}
								number_of_floors={el.number_of_floors}
								floors={el.floor}
								item_status={el.item_status}
								hidden_des={el.description_hidden}
								admin_id={el.adminid}
								user_id={userData.id}
								user_type={userData.type}
								all={el}
							/>
							<div className='broker_owner_info'>
								{(userData.type !== 'broker' ||
									Number(el.adminid) === Number(userData.id)) && (
									<>
										<b>Գույքի տեր</b>
										<div className='owner_data'>
											<span>{el.owner_name}</span>
											<b>{el.owner_phone}</b>
										</div>
										<hr />
									</>
								)}
								<b>Գործակալ</b>
								<br />
								{brokerData.map(element => {
									return (
										<div className='broker_info'>
											<div>
												<span>
													{element.name} {element.surname}
												</span>
												<br />
												<b>{element.phone}</b>
												<br />
												<b>{element.email}</b>
											</div>
											<img src={element.image} />
										</div>
									)
								})}
							</div>
						</div>
					)
				})}
			</div>
		</div>
	)
};

export default Announcements;
