import React, { useEffect } from "react";
import { useState } from "react";
import AnnouncementsFilter from "../../announcements-filter/AnnouncementsFilter";
import { FaFileCircleCheck } from "react-icons/fa6";
import axios from "axios";
import ElementCard from "../../element-card/ElementCard";

const MyAnnouncements = ({ userData }) => {
  const [ann, setAnn] = useState([]);
  const [showAnn, setShowAnn] = useState([])
  const [brokers, setBrokers] = useState([]);
  const [annImages, setAnnImages] = useState([]);

  useEffect(() => {
    const get_all_items = async () => {
      try {
        const { data } = await axios.get(
          'https://service.homely.am/api/items/all'
        )
        setAnn(data.all_items.reverse())
      } catch (err) {
        console.log(err)
      }
    }
    get_all_items()
    const get_items = async () => {
      try {
        const { data } = await axios.get(
          "https://service.homely.am/api/broker/items",
          {
            params: { adminid: String(userData.id) },
          }
        );
        setShowAnn(data.all_items.reverse())
        setAnnImages(data.all_images);
      } catch (error) {
        console.log(error);
      }
    };
    get_items();
  }, []);
  function filtracion(fi) {
			setShowAnn(
				ann.filter(el => {
					if (fi.id && Number(fi.id) !== el.id) return false
          if(fi.ownerNumber && fi.ownerNumber !== el.owner_phone) return false
					if (
						fi.address.length &&
						(el.street == null ||
							!el.street.toLowerCase().startsWith(fi.address.toLowerCase()))
					)
						return false
					if (fi.regions.length && !fi.regions.includes(el.region)) return false
					if (fi.rentOrSell && el.type !== fi.rentOrSell) return false
					if (
						fi.roomsNumber.length &&
						!fi.roomsNumber.includes(el.number_of_rooms)
					)
						return false
					if (
						fi.status != undefined &&
						fi.status != [] &&
						!fi.status.includes(el.status)
					)
						return false
					if (
						fi.building_type != undefined &&
						fi.building_type != [] &&
						!fi.building_type.includes(el.type_of_building)
					)
						return false
					if (fi.floor_min && Number(el.floor) < Number(fi.floor_min))
						return false
					if (fi.floor_max && Number(el.floor) > Number(fi.floor_max))
						return false
					if (fi.price_from && Number(el.price) < Number(fi.price_from))
						return false
					if (fi.price_to && Number(el.price) > Number(fi.price_to))
						return false
					if (fi.itemType.length && !fi.itemType.includes(el.item_type))
						return false
					return true
				})
			)
		}

  return (
    <div className="workers-page">
      <div style={{ height: "100px" }}></div>
      <p
        style={{
          textAlign: "center",
          color: "#fff",
          fontSize: "18px",
          background: "#202137",
          paddingBottom: "15px",
          paddingTop: "15px"
        }}
      >
        Միայն Ձեր հայտարարությունները
      </p>
      <AnnouncementsFilter onFiltracion={(fi) => filtracion(fi)}/>
      <div className="all_ann">
        {showAnn.map((el) => {
          const itemIMAGES = annImages.filter((e) => e.item_id == el.id);
          const brokerData = brokers.filter((e) => e.id === Number(el.adminid));
          return (
            <div className="el_data_card">
              <ElementCard
                price={el.price}
                id={el.id}
                img={
                  itemIMAGES[0] &&
                  `https://service.homely.am/storage/images/${itemIMAGES[0].image}`
                }
                title={el.title}
                status={null}
                area={el.area}
                bathrooms={el.number_of_bathrooms}
                rooms={el.number_of_rooms}
                number_of_floors={el.number_of_floors}
                floors={el.floor}
                item_status={el.item_status}
                hidden_des={el.description_hidden}
                admin_id={el.adminid}
                user_id={userData.id}
                user_type={userData.type}
                all={el}
              />
              <div className="broker_owner_info">
                {(userData.type !== "broker" ||
                  Number(el.adminid) === Number(userData.id)) && (
                  <>
                    <b>Գույքի տեր</b>
                    <div className="owner_data">
                      <span>{el.owner_name}</span>
                      <b>{el.owner_phone}</b>
                    </div>
                  </>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MyAnnouncements;
